import { graphql, useStaticQuery } from "gatsby"
import { PostItemStyles } from "../components/Post/PostStyles";

const useTags = () => {
  const {
    allContentfulPost: { nodes },
  } = useStaticQuery(graphql`
  query MyQuery {
    allContentfulPost {
      nodes {
        tags
      }
    }
  }
  `)
  console.log("getting tags");
  console.log(nodes);
  var dict = {};
  nodes.forEach(function(item) {
    if (item.tags === null || item.tags === "null") {
      //skip null tags
    }
    else if (item.tags in dict) { 
      dict[item.tags] += 1;
    } else {
      dict[item.tags] = 1;
    }
  });
  return dict;
}

export default useTags
