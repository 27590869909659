import React from "react"
import PostItem from "../Post/PostItem"
import useAllPost from "../../hooks/use-all-blog-post"


//insteaf of all posts, then use the post
const PostFeed = ({contentfulPage, currentTag}) => {
  var allPosts = useAllPost()
  
  if(currentTag !== "all") {
    var taggedPost = [];
    allPosts.forEach(function(post) {
      if (post.tags !== null && post.tags[0] === currentTag) {
        taggedPost.push(post);
      }
    })
    allPosts = taggedPost;
  }

  return allPosts.map((node, index) => {
    return <PostItem key={index} node={node} />
  })
}

export default PostFeed
