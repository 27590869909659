import styled from "styled-components"

export const FiltersStyles = styled.section`
{
    padding: 0 var(--borderSpacing);
    margin-top:  15px;
    @media (max-width: 768px) {
        margin-bottom: 100px;
    }
}

> div {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    font-size: 20px;
    text-align: center;
  }

> div > p {
    margin-top: 0px;
    margin-bottom 0px;
}
`
