import * as React from "react"
import { Link } from "gatsby"
import Button from "../Button/Button"
import { FiltersStyles } from "./FiltersStyles"




const Filters = ({ tags, currentTag }) => {
  // console.log("currentTag")
  // console.log(currentTag)
  return (
    <FiltersStyles>
      {currentTag === "all" ? (
        <div><p>All Articles</p></div>
      ) : (
        <div><a href={`/articles/`}>All Articles</a></div>

      )}
      {Object.keys(tags).map(tag => {
        if (tag === currentTag) {
          return (
            <div><p>{tag} ({tags[tag]})</p></div>
          )
        } else {
          return (
            <div><a href={`/articles/${tag}/`}>{tag} ({tags[tag]})</a></div>
          )
        }
      })}
    </FiltersStyles>
)

  //buttons not rendering properly, so using alt method of links


  // return (
  //     <div>
  //       {currentTag === "all" ? (
  //         <Button
  //           style={{
  //             whiteSpace: "nowrap",
  //             color: "var(--primary100)",
  //           }}
  //         >
  //           All Articles
  //         </Button>
  //       ) : (
  //         <Button
  //           url="/articles"
  //           style={{
  //             whiteSpace: "nowrap",
  //           }}
  //         >
  //           All Posts
  //         </Button>
  //       )}
  //       {Object.keys(tags).map(tag => {
  //         if (tag === currentTag) {
  //           return (
             
  //             <Button
  //               style={{
  //                 whiteSpace: "nowrap",
  //                 color: "var(--primary100)",
  //               }}
  //             >
  //               {tag} ({tags[tag]})
  //             </Button>
  //           )
  //         } else {
  //           //buttons not rendering proplery
  //           return (
  //             // <div><a href={`/article/${tag}/`}>{tag} ({tags[tag]})</a></div>
  //             <div>
  //             <Button
  //               url={`/articles/${tag}/`}
  //               key={tag}
  //               // style={{
  //               //   whiteSpace: "nowrap",
  //               // }}
  //             >
                
  //               {tag}
  //             </Button>
  //             </div>
  //           )
  //         }
  //       })}
  //     </div>
  // )


}

export default Filters