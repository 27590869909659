import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
// import ProductFeed from "../components/Feeds/ProductFeed"
import PostFeed from "../components/Feeds/PostFeed"

import Filters from "../components/Filters/Filters"
import useTags from "../hooks/use-tags"

const getTemplate = (contentfulPage, currentTag) => {
  const { feedType } = contentfulPage

  switch (feedType) {
    // case "Products":
    //   return <ProductFeed {...contentfulPage} />

    default:
      console.log("CURR Tag")
      console.log(currentTag)
      // currentTag = "other"

      return <PostFeed {...{contentfulPage, currentTag}} />
      //I want to pass in the tags as well
  }
}

//prev had {data} as a prop
const FeedTemplate = (contentfulPage, pageContext) => {
  const headerImage = getImage(contentfulPage.headerImage)
  const tags = useTags()
  var currentTag = "all"

  //couldn't pull the page context correctly so I just got the url
  // const pageTag = pageContext.tag

  // if (typeof pageTag !== 'undefined') {
  //   console.log("PAGE LOG")
  //   console.log(pageTag)
  //   currentTag = pageTag.tag
  // }
  if (typeof window !== `undefined`) { 
    if (window.location.pathname !== "/articles" && window.location.pathname !== "/articles/") {
      currentTag = window.location.pathname;
      currentTag = currentTag.replace("articles/","");
      currentTag = currentTag.replace('/','');
      currentTag = currentTag.replace('/','');
      console.log(currentTag);
    }
  }



  return (
    <>
      <Seo title={contentfulPage.title} />
      <Layout>
        <SimpleBanner title={contentfulPage.title}>
        {<GatsbyImage
            className="banner__image"
            image={headerImage}
            alt={`${contentfulPage.title} feed`}
          /> 
          &&
          <StaticImage className="banner__image" src="../../mma-photos/conor-dustin.jpeg" />}
        </SimpleBanner>
        <Filters currentTag={currentTag} tags={tags}/>

        <div className="section">
          <div className="feed">{getTemplate(contentfulPage, currentTag)}</div>
        </div>
      </Layout>
    </>
  )
}


export default FeedTemplate
